import React, {useContext, useRef, useState} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import {Autocomplete} from '@react-google-maps/api';
import axios from 'axios';

import {Input} from '../Input';
import {Button} from '../Button';
import {CustomSelect} from '../CustomSelect';
import {DataContext} from '../../App';

import {Close} from '../../assets/icons';
import Success from '../../assets/images/success.png';

export const Modal = (props) => {

    const {visible, onClose} = props;

    const captchaRef = useRef(null);
    const [success, setSuccess] = useState(false);
    const [recaptcha, setRecaptcha] = useState(false);
    const [errors, setErrors] = useState({});
    const [searchResult, setSearchResult] = useState('Result: none');
    const [serviceSpeed, setServiceSpeed] = useState(null);
    const [contractLength, setContractLength] = useState(null);

    const [data, setData] = useState({
        name: '',
        companyName: '',
        email: '',
        phone: '',
        address: '',
        level: '',
    });

    const selectData = useContext(DataContext);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setData(prevState => ({
            ...prevState,
            [name]: value
        }));
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(data);

        const captchaValue = captchaRef.current.getValue()
        if (!captchaValue) {
            setRecaptcha(true);
            return
        } else {
            setRecaptcha(false);
        }

        const newErrors = {};
        Object.keys(data).forEach(key => {
            if (data[key].trim() === '') {
                newErrors[key] = 'Field is required';
            }
        });

        setErrors(newErrors);

        if (Object.keys(newErrors).length > 0) {
            return;
        }

        try {
            const {name, companyName, email, phone, address, level} = data;

            const url = `${process.env.REACT_APP_URL}wp-admin/admin-ajax.php?action=contact_request&name=${name}&companyName=${companyName}&email=${email}&phone=${phone}&address=${address}&serviceSpeed=${serviceSpeed.value}&contractLength=${contractLength.value}&level=${level}`;
            const response = await axios.get(url);
            setSuccess(response?.data?.success);
        } catch (e) {
            console.error(e);
        }
    };

    function onLoad(autocomplete) {
        setSearchResult(autocomplete);
    }

    function onPlaceChanged() {
        if (searchResult != null) {

            const place = searchResult.getPlace();

            setData(prevState => ({
                ...prevState,
                ['address']: place?.formatted_address,
            }));
        }
    }

    const handleSpeedChange = (selectedOption) => {
        setServiceSpeed(selectedOption);
    };

    const handleContractChange = (selectedOption) => {
        setContractLength(selectedOption);
    };

    const contractOptions = selectData?.form?.your_contract_length.map(item => ({
        value: item.item,
        label: item.item
    }));

    const speedOptions = selectData?.form?.your_service_speed.map(item => ({
        value: item.item,
        label: item.item
    }));

    return (
        <div
            className={`fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-75 z-50 ${visible ? 'block' : 'hidden'}`}>
            <div
                className={`fixed top-0 left-0 w-full h-full bg-dark-gray backdrop-blur-sm opacity-50 ${visible ? 'block' : 'hidden'}`}></div>
            <div className={`bg-white flex-col flex items-center relative overflow-auto blur-background
                         lg:px-16 lg:py-15 lg:w-204 lg:mx-0 lg:max-h-full lg:rounded-5
                         xs:px-6 xs:pb-6 xs:pt-12 xs:w-full xs:mx-6 xs:max-h-full xs:rounded`}>
                <button className={`absolute text-gray-500 hover:text-gray-800
                                lg:top-7.5 lg:right-7.5
                                xs:top-4 xs:right-4`} onClick={onClose}>
                    <Close/>
                </button>
                {!success ? (
                    <form className={'flex flex-col items-center w-full justify-center mx-auto'}
                          onSubmit={handleSubmit}>
                        <div
                            className={'text-dark-gray font-raleway-regular 2xl:text-6 lg:text-4 md:text-6 xs:w-full xs:text-3.5 text-center'}
                            dangerouslySetInnerHTML={{__html: selectData?.form?.head_text}}>
                        </div>
                        <div className={`text-dark-gray font-nexa-bold
                                 lg:text-9 lg:mb-10
                                 xs:text-5 xs:mb-6`} dangerouslySetInnerHTML={{__html: selectData?.form?.title}}>
                        </div>
                        <div className={`flex flex-col w-full space-y-6
                                 lg:mb-10 xs:mb-6`}>
                            <Input
                                type={'text'}
                                required={true}
                                name={'name'}
                                value={data.name}
                                onChange={handleChange}
                                label={'Your Name'}
                                placeholder={'Enter your first name'}
                                error={errors.name}
                            />
                            <Input
                                type={'text'}
                                required={true}
                                name={'companyName'}
                                value={data.companyName}
                                onChange={handleChange}
                                label={'Company Name'}
                                placeholder={'Enter your company name'}
                                error={errors.companyName}
                            />
                            <Input
                                type={'email'}
                                required={true}
                                name={'email'}
                                value={data.email}
                                onChange={handleChange}
                                label={'Your Email'}
                                placeholder={'Enter your email'}
                                error={errors.email}
                            />
                            <Input
                                type={'text'}
                                required={true}
                                name={'phone'}
                                value={data.phone}
                                onChange={handleChange}
                                label={'Your Phone Number'}
                                placeholder={'Enter your phone number'}
                                error={errors.phone}
                            />
                            <Autocomplete
                                onPlaceChanged={onPlaceChanged}
                                onLoad={onLoad}
                                restrictions={{country: 'au'}}
                                bounds={{
                                    //first bound coordinate
                                    north: selectData?.section_map?.first_bound_point?.lat || 0, //lat
                                    west: selectData?.section_map?.first_bound_point?.lng || 0, //lng
                                    //second bound coordinate
                                    south: selectData?.section_map?.second_bound_point?.lat || 0, //lat
                                    east: selectData?.section_map?.second_bound_point?.lng || 0, //lng
                                }}
                                options={{strictBounds: true}}
                            >
                                <Input
                                    type={'text'}
                                    required={true}
                                    name={'address'}
                                    value={data.address}
                                    onChange={handleChange}
                                    label={'Your Address'}
                                    placeholder={'Enter your address'}
                                    error={errors.address}
                                />
                            </Autocomplete>
                            <Input
                                type={'text'}
                                required={true}
                                name={'level'}
                                value={data.level}
                                onChange={handleChange}
                                label={'Your Suite/Level'}
                                placeholder={'Enter your suite/level'}
                                error={errors.level}
                            />
                            <CustomSelect
                                label={'Your Service Speed'}
                                name={'serviceSpeed'}
                                options={speedOptions}
                                required={true}
                                placeholder={'Enter your service speed'}
                                value={serviceSpeed}
                                onChange={handleSpeedChange}
                            />
                            <CustomSelect
                                label={'Your Contract Length'}
                                name={'contractLength'}
                                options={contractOptions}
                                required={true}
                                placeholder={'Enter your contract length'}
                                value={contractLength}
                                onChange={handleContractChange}
                            />
                            <div className={'w-full'}>
                                <ReCAPTCHA
                                    ref={captchaRef}
                                    sitekey={'6LeporYpAAAAACHjtDWlBMWF07-hXSATsskmhif4'}
                                />
                                {recaptcha && <span className={'text-5 text-red'}>required</span>}
                            </div>
                            <div
                                className={'text-dark-gray font-raleway-regular 2xl:text-6 lg:text-4 md:text-6 xs:w-full xs:text-3.5 text-center'}
                                dangerouslySetInnerHTML={{__html: selectData?.form?.buttom_text}}>
                            </div>
                        </div>
                        <div className={'lg:w-77.75 xs:w-full'}>
                            <Button
                                styles={'text-white bg-black lg:text-5 xs:text-3.5'}
                                title={selectData?.form?.button_title}
                            />
                        </div>
                    </form>
                ) : (
                    <div className={'flex flex-col items-center w-full justify-center mx-auto'}>
                        <div className={'lg:mb-7.5 xs:mb-6'}>
                            <img src={Success} alt="success"/>
                        </div>
                        <div className={`text-dark-gray font-nexa-bold
                                 lg:text-9 lg:mb-2.5 lg:text-current
                                 xs:text-5 xs:mb-3.5 xs:text-center`}>Thank You For Your Order
                        </div>
                        <div className={`text-dark-gray font-raleway-regular
                                         lg:text-4 lg:mb-7.5 lg:text-current
                                         xs:text-3.5 xs:mb-6 xs:text-center`}>
                            We will contact you as soon as possible!
                        </div>
                        <div className={'lg:w-77.75 xs:w-full'}>
                            <Button
                                onClick={() => {
                                    onClose();
                                    setData({
                                        name: '',
                                        companyName: '',
                                        email: '',
                                        phone: '',
                                        address: '',
                                        serviceSpeed: '',
                                        contractLength: '',
                                    })
                                    setSuccess(false)
                                    setRecaptcha(false)
                                    setErrors({})
                                }}
                                styles={'text-white bg-black lg:text-5 xs:text-3.5'}
                                title={'OK'}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
